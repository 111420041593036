// ----------------------------------------------------------------------------
// Common variables

// info: these variables are part of the adaptative design stuff be careful while changing them

:root {
    //TODO: complete the color scheme for dark/light OS version
    //FIXME: blue color while clicking the menu items
    //FIXME: menu item remains black on "ABOUT" page

    --bd-maya-icecolor: #C1E3ED;
    --bd-maya-icecolor-rgb: rgb(193, 227, 237);
    --bd-dark-icecolor: #94CADA;
    --bd-ugloocolor: #0095b6;
    --bd-ugloocolor-rgb: 0, 149, 182;

    // --bs-emphasis-color: white;
    // --bs-emphasis-color-rgb: 255,255,255;
  
    --bs-link-color: var(--bs-white);
    --bs-link-color-rgb: 255,255,255;
 //   --bs-link-opacity: 1;
    // --bs-link-hover-color: yellow;
    // --bs-link-hover-color-rgb: 255, 255, 0;
  
    --bs-nav-link-font-size: 12pt;
    --bs-nav-bar-hover-color: var(--bd-maya-icecolor);
}


// ----------------------------------------------------------------------------
// Home page

.home {
    //TODO: cleanup. Seem unused
    --bd-bkgnd: rgb(233, 230, 227);
    --bd-accent: rgb(255, 228, 132);
    --bd-violet-rgb: 112.520718, 44.062154, 249.437846;
    --bd-accent-rgb: 255, 228, 132;
    --bd-pink-rgb: 214, 51, 132;

    .bd-masthead {
        padding: 0.1rem 0;
        background-image: radial-gradient(ellipse at center, var(--bs-white) 0%, 30%, var(--bd-maya-icecolor) 100%);
    }

    .bkgnd-iceblue-linear-gradient {
        background-image: linear-gradient(var(--bd-maya-icecolor) 0%, var(--bs-white) 50%);
    }

    .bkgnd-iceblue-linear-gradient-2 {
        background-image: linear-gradient(var(--bs-white) 0%, 50%, var(--bd-maya-icecolor) 100%);
    }

    .btn-bd-primary {
        --bs-btn-font-weight: 600;
        --bs-btn-color: var(--bs-white);
        --bs-btn-bg: var(--bd-ugloocolor);
        --bs-btn-border-color: var(--bd-ugloocolor);
        --bs-btn-border-radius: .5rem;
        --bs-btn-hover-color: var(--bd-ugloocolor);
        --bs-btn-hover-bg: var(--bd-bkgnd);
        --bs-btn-hover-border-color: var(--bd-ugloocolor);
        --bs-btn-focus-shadow-rgb: var(--bd-violet-rgb);
        --bs-btn-active-color: var(--bs-btn-hover-color);
        --bs-btn-active-bg: var(--bd-ugloocolor);
        --bs-btn-active-border-color: var(--bd-ugloocolor);
    }
}

.iceberg-position {
    position: relative;
    //top: -25em;
    left: 65vw;
    margin-bottom: -35em;

    transition: transform 0.2s ease-in-out;

    &:hover {
        animation: loader 1.2s;
    }
}

@keyframes loader {
    0% {
        top: 0;
    }

    40% {
        scale: (1.2);
    }

    100% {
        top: -25em;
    }
}

// ----------------------------------------------------------------------------
// Styles for single pages

a {
    --bs-link-color-rgb: var(--bd-ugloocolor-rgb);
}

// ----------------------------------------------------------------------------
// Styles for Menu bar

.navbar {
    @extend .navbar-light;

    --bs-navbar-color: var(--bs-white);
    --bs-link-hover-color: var(--bd-ugloocolor);
    --bs-navbar-nav-link-padding-x: 1rem;

    padding: .5rem 0;
    background-color: var(--bd-maya-icecolor);
}

.navbar-brand {
    transition: transform 0.2s ease-in-out;

    &:hover {
        transform: rotate(-2deg) scale(1.3);
    }
}

.navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 3px;
    margin-bottom: 0;
    list-style: none;
}

// lpiot: customized
.nav-item {
    --bs-nav-link-padding-x: 0;
    --bs-nav-link-padding-y: 0.5rem;
    --bs-nav-link-font-weight: ;
    --bs-nav-link-color: var(--bs-white);
    --bs-nav-link-hover-color: var(--bd-ugloocolor);
    --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
    display: block;
    margin: 5px;
    background-color: var(--bd-ugloocolor);
    border-color: var(--bd-ugloocolor);
    border-radius: 25px / 30px;
    border-style: solid;
    border-width: 1px;
    box-shadow: 2px 4px rgba(var(--bs-dark-rgb), .6);
    font-size: 11pt;

    &:hover {
        transform: scale(1.1);
        box-shadow: none;
        color: var(--bd-ugloocolor);
        background-color: var(--bd-maya-icecolor);
        border-color: var(--bd-ugloocolor);
    }
}

.nav-item-social {
    display: block;
    background-color: var(--bd-ugloocolor);
    border-radius: 25px / 30px;
    box-shadow: 0px 4px rgba(var(--bd-ugloocolor-rgb), .6);
    font-size: 13pt;
    margin: 0px;
    display: flex;
}

.me-auto {
    margin-left: auto !important;
    ;
    padding-right: 2em;
}

.navbar-collapse {
    flex-grow: 0;
}

// .feather {
//     stroke: var(--bs-white);
// }

a .feather:hover {
    stroke: rgba(var(--bs-dark-rgb), .6);
    fill: rgba(var(--bs-dark-rgb), .6);
}


// ----------------------------------------------------------------------------
// Styles for single pages

.text-ugloofontfamily {
    font-family: "Montserrat", "Helvetica", "Arial", sans-serif;
    // font-weight: 500;
}

.text-ugloocolor {
    color: var(--bd-ugloocolor) !important;
}

.list {
    .card {
        transition: transform 0.2s ease-in-out;

        &:hover {
            transform: scale(1.01);
        }
    }
}

b,
code,
strong {
    color: var(--bd-ugloocolor) !important;
}

b,
strong {
    font-family: "Montserrat", "Helvetica", "Arial", sans-serif;
}

// ----------------------------------------------------------------------------
// Styles for single pages

.py-3 h1 {
    color: var(--bd-ugloocolor);
    font-family: "Montserrat", "Helvetica", "Arial", sans-serif;
    font-size: 4rem;
    font-weight: bold;
    margin-bottom: 3rem;
    padding-bottom: 1em;
    padding-top: 1em;
    // text-shadow: 1px 1px 3px black;
    text-transform: uppercase;
}

.h1b {
    font-family: "Montserrat", "Helvetica", "Arial", sans-serif;
    font-weight: bolder;
    font-size: 2rem;
    line-height: normal;
    position: relative;
    top: -7em;
    z-index: auto;
    color: var(--bd-ugloocolor);
    padding-right: 1em;
    margin-bottom: -7em;
}

.test-lpiot {
    position: relative;
    top: 1rem;
    right: -30rem;
    margin-bottom: -6em;
}

.py-3 h2 {
    // background: linear-gradient(to bottom, var(--bs-white) 0%, 20%, var(--bd-maya-icecolor) 80%, var(--bs-white) 100%);
    color: var(--bd-ugloocolor);
    font-family: "Montserrat", "Helvetica", "Arial", sans-serif;
    font-weight: bold;
    margin-bottom: 3rem;
    margin-top: 7rem;
    padding-bottom: 0.5em;
    padding-top: 0.5em;
    text-align: center;
    // text-shadow: 1px 1px 3px black;
    text-transform: uppercase;
}

.py-3 h3 {
    font-family: "Montserrat", "Helvetica", "Arial", sans-serif;
    color: var(--bd-ugloocolor);
    margin-top: 5rem;
}

.alert-warning {
    margin-left: 3em;
    margin-right: 3em;
    border-radius: .5rem;
}

// ----------------------------------------------------------------------------
// Styles for list pages
.card-title {
    margin-top: 0 !important;
}

// ----------------------------------------------------------------------------
// Styles for flickity carousel

/*! Flickity v2.3.0
https://flickity.metafizzy.co
---------------------------------------------- */
.flickity-enabled {
    position: relative
}

.flickity-enabled:focus {
    outline: 0
}

.flickity-viewport {
    overflow: hidden;
    position: relative;
    height: 100%;
}

.flickity-slider {
    position: absolute;
    width: 100%;
    height: 100%
}

.flickity-enabled.is-draggable {
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.flickity-enabled.is-draggable .flickity-viewport {
    cursor: move;
    cursor: -webkit-grab;
    cursor: grab
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
    cursor: -webkit-grabbing;
    cursor: grabbing
}

.flickity-button {
    position: absolute;
    background: hsla(0, 0%, 100%, .75);
    border: none;
    color: #333
}

.flickity-button:hover {
    background: #fff;
    cursor: pointer
}

.flickity-button:focus {
    outline: 0;
    box-shadow: 0 0 0 5px #19f
}

.flickity-button:active {
    opacity: .6
}

.flickity-button:disabled {
    opacity: .3;
    cursor: auto;
    pointer-events: none
}

.flickity-button-icon {
    fill: currentColor
}

.flickity-prev-next-button {
    top: 50%;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    transform: translateY(-50%)
}

.flickity-prev-next-button.previous {
    left: 10px
}

.flickity-prev-next-button.next {
    right: 10px
}

.flickity-rtl .flickity-prev-next-button.previous {
    left: auto;
    right: 10px
}

.flickity-rtl .flickity-prev-next-button.next {
    right: auto;
    left: 10px
}

.flickity-prev-next-button .flickity-button-icon {
    position: absolute;
    left: 20%;
    top: 20%;
    width: 60%;
    height: 60%
}

.flickity-page-dots {
    position: absolute;
    width: 100%;
    bottom: -25px;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
    line-height: 1
}

.flickity-rtl .flickity-page-dots {
    direction: rtl
}

.flickity-page-dots .dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 8px;
    background: #333;
    border-radius: 50%;
    opacity: .25;
    cursor: pointer
}

.flickity-page-dots .dot.is-selected {
    opacity: 1
}

// Custom
.carousel {
    //   background: var(--bd-bkgnd);
}

.carousel-cell {
    background-image: url("../images/icy-texture.png");
    box-shadow: 3px 3px var(--bs-black);
    border-radius: 30px;
    margin-right: 20px;
    // overflow: hidden;
    &:after {
        opacity: 0.6;
    }
    .card {
        background-color: unset;
        border: unset;

    }
    .card .card-body {
        margin-bottom: 30px;
    }
}

.carousel-cell img {
    display: block;
    height: 200px;
}

@media screen and (min-width: 768px) {
    .carousel-cell img {
        height: 400px;
    }
}
