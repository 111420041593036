// Custom.scss
// Option B: Include parts of Bootstrap
// https://getbootstrap.com/docs/5.2/customize/sass/

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "bootstrap/functions";

// 2. Include any default variable overrides here
@import "variable-overrides"; 

// 3. Include remainder of required Bootstrap stylesheets
@import "bootstrap/variables";
@import "bootstrap/variables-dark";

// 4. Include any default map overrides here

// 5. Include remainder of required parts
@import "bootstrap/maps";
@import "bootstrap/mixins";
@import "bootstrap/utilities";

// 6. Optionally include any other parts as needed
@import "bootstrap/root";
@import "bootstrap/reboot";
@import "bootstrap/type";
@import "bootstrap/images";
@import "bootstrap/containers";
@import "bootstrap/grid";
@import "bootstrap/tables";
//@import "bootstrap/forms";
@import "bootstrap/buttons";
@import "bootstrap/transitions";
@import "bootstrap/dropdown";
//@import "bootstrap/button-group";
@import "bootstrap/nav";
@import "bootstrap/navbar";
@import "bootstrap/card";
@import "bootstrap/accordion";
@import "bootstrap/breadcrumb";
//@import "bootstrap/pagination";
@import "bootstrap/badge";
@import "bootstrap/alert";
//@import "bootstrap/progress";
@import "bootstrap/list-group";
//@import "bootstrap/close";
//@import "bootstrap/toasts";
//@import "bootstrap/modal";
//@import "bootstrap/tooltip";
//@import "bootstrap/popover";
@import "bootstrap/carousel";
//@import "bootstrap/spinners";
//@import "bootstrap/offcanvas";
//@import "bootstrap/placeholders";
@import "bootstrap/helpers";

// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "bootstrap/utilities/api";

// 8. Add additional custom code here
@import "custom"; // Theme SASS
@import "styles"; // Site SASS (assets/sass/styles.scss)
// @import "modal"; // Modal alert SASS (assets/sass/modal.scss)

@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600"); // Montserrat fonts